export default {
    seo: {
      title: 'Реквизиты | Банк 131',
      description: 'Реквизиты ООО «Банк 131»'
    },
    title: 'Реквизиты',
    content: {
            rectangle: [
                {
                    title: 'ПОЛНОЕ НАИМЕНОВАНИЕ',
                    description: 'Общество с ограниченной ответственностью «Банк 131»'
                },
                {
                    title: 'СОКРАЩЕННОЕ НАИМЕНОВАНИЕ',
                    description: 'ООО «Банк 131»'
                },
                {
                    title: 'ПОЛНОЕ ФИРМЕННОЕ НАИМЕНОВАНИЕ НА АНГЛИЙСКОМ ЯЗЫКЕ',
                    description: 'Limited liability company «Bank 131»'
                },
                {
                    title: 'СОКРАЩЕННОЕ ФИРМЕННОЕ НАИМЕНОВАНИЕ НА АНГЛИЙСКОМ ЯЗЫКЕ',
                    description: 'LLC «Bank 131»'
                },
                {
                    title: 'ЮРИДИЧЕСКИЙ АДРЕС',
                    description: '420012, Российская Федерация, Республика Татарстан, город Казань, улица Некрасова, д. 38'
                },
                {
                    title: 'ПОЧТОВЫЙ АДРЕС',
                    description: '420012, Российская Федерация, Республика Татарстан, город Казань, улица Некрасова, д. 38'
                },
                {
                    title: 'ФАКТИЧЕСКИЙ АДРЕС',
                    description: '420012, Российская Федерация, Республика Татарстан, город Казань, улица Некрасова, д. 38'
                },
                {
                    title: 'РЕГИСТРАЦИОННЫЙ НОМЕР',
                    description: '3538'
                },
                {
                    title: 'ЛИЦЕНЗИЯ',
                    description: 'Базовая лицензия на осуществление банковских операций со средствами в рублях и иностранной валюте (с правом привлечения во вклады денежных средств физических лиц) и на осуществление банковских операций с драгоценными металлами № 3538 от 16.05.2024.'
                },
                {
                    title: 'УСТАВНЫЙ КАПИТАЛ БАНКА',
                    description: '340 000 000 рублей'
                },
                {
                    title: 'УЧАСТИЕ В СИСТЕМЕ ОБЯЗАТЕЛЬНОГО СТРАХОВАНИЕ ВКЛАДОВ',
                    description: 'Да'
                },
                {
                    title: 'ИНН / КПП',
                    description: '1655415696 / 165501001'
                },
                {
                    title: 'ОГРН',
                    description: '1191690025746'
                },
                {
                    title: 'ДАТА ПРИСВОЕНИЯ ОГРН',
                    description: '21 марта 2019 года'
                },
                {
                    title: 'КОРРЕСПОНДЕНТСКИЙ СЧЕТ',
                    description: '30101810822029205131 в Отделении — Национальном банке по Республике Татарстан Волго-Вятского главного управления Центрального банка Российской Федерации'
                },
                {
                    title: 'БИК БАНКА',
                    description: '049205131'
                },
                {
                    title: 'КЛАССИФИКАТОРЫ В СТАТИСТИЧЕСКОМ РЕГИСТРЕ',
                    description: 'ОКПО 36795400 ОКАТО 92401367000 <br/>' +
                        'ОКТМО 92701000001 ОКОГУ 4210014<br/>' +
                        'ОКФС 16 ОКОПФ 12300<br/>' +
                        'ОКВЭД 64.19 Денежное посредничество прочее'
                },
                {
                    title: 'ОФИЦИАЛЬНЫЕ САЙТЫ БАНКА',
                    description: 'www.131.ru'
                },
                {
                    title: 'ЭЛЕКТРОННАЯ ПОЧТА',
                    description: 'info@131.ru'
                },
                {
                    title: 'ТЕЛЕФОН',
                    description: '8 800 100 13 10'
                },
                {
                    title: 'РЕЖИМ РАБОТЫ',
                    description: 'Понедельник – пятница: с 09:00 до 18:00 (обед с 13:00 до 14:00, время мск);<br/>' +
                        'Суббота - воскресенье: выходные (нерабочие) дни.'
                },
            ],
            description: 'Вся размещенная на сайте информация представлена только для общего ознакомления и не является публичной офертой.<br/>' +
                '<br/>' +
                'Лицо, ответственное за полноту, достоверность и актуальность публикуемых на Web-сайте сведений: Ерошина Елена, press@131.ru<br/>' +
                '<br/>' +
                'Надзор за деятельностью ООО «Банк 131», рег номер 3538: Банковский надзор за деятельностью кредитной организации (ООО «Банк 131») осуществляет Служба текущего банковского надзора Банка России.<br/>' +
                '<br/>' +
                'Телефоны Контактного центра Центрального банка Российской Федерации: адрес: 107016, г. Москва, ул. Неглинная, 12, тел. 8-800-250-4072, 8-495-771-9100.<br/>' +
                '<br/>' +
                'Надзор за соблюдением требований законодательства Российской Федерации в сфере защиты прав потребителей финансовых услуг осуществляет Служба по защите прав потребителей и обеспечению доступности финансовых услуг Банка России.<br/>' +
                '<br/>' +
                'Обращение о нарушении действиями (бездействием) кредитной организации законодательства Российской Федерации, а также охраняемых законом прав и интересов физических или юридических лиц может быть направлено для рассмотрения в Банк России через интернет-приемную <br/>' +
                '(www.cbr.ru/Интернет-приемная).<br/>' +
                '<br/>' +
                'Вся размещенная на сайте информация представлена только для общего ознакомления <br/>' +
                'и не является публичной офертой. Та информация, которая на данный момент отсутствует, <br/>' +
                'скоро появится и мы добавим ее на сайт.'
    },
}